/* correct style name - the same as in our design system*/
@font-face {
  font-family: 'Nutmeg Headline-Ultra Black';
  src: url('NutmegHeadline-UltraBlack.woff2') format('woff2'),
    url('NutmegHeadline-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('Roboto-Regular.woff2') format('woff2'), url('Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('Roboto-Medium.woff2') format('woff2'), url('Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-SemiBold';
  src: url('Roboto-Bold.woff2') format('woff2'), url('Roboto-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/*the next fonts must be for backward compatible, nedd to remove it after cleaning all code */
@font-face {
  font-family: 'NutmegHeadline-UltraBlack';
  src: url('NutmegHeadline-UltraBlack.woff2') format('woff2'),
    url('NutmegHeadline-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('Roboto-Bold.woff2') format('woff2'), url('Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
